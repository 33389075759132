import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isOpenFilters : false,
};

const UsersSlice = createSlice({
	name     : "users",
	initialState,
	reducers : {
		setIsOpenFilters    : (state, action) => void(state.isOpenFilters = action.payload),
		toggleIsOpenFilters : (state, action) => void(state.isOpenFilters = !state.isOpenFilters),
	},
});

export default UsersSlice;
