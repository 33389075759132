import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	direntId            : null,
	direntIdPermissions : null,
	tree                : {},
	dirents             : {},
	references          : {},
};

const DirentsSlice = createSlice({
	name     : "dirents",
	initialState,
	reducers : {
		setDirentId            : (state, action) => void(state.direntId = action.payload),
		setDirentIdPermissions : (state, action) => void(state.direntIdPermissions = action.payload),
		saveDirentsGridData    : (state, action) => {
			const ROOT     = "root";
			const direntId = action.payload.direntId ?? ROOT;

			const dirents      = action.payload?.result?.data?.docs ?? [];
			const page         = action.payload?.result?.data?.page ?? 1;
			const referenceKey = `${direntId}:${page}`;

			if (!(referenceKey in state.references)) {
				state.references[referenceKey] = {};
			}

			if (!((direntId) in state.dirents)) {
				state.dirents[direntId] = {};
			}

			const listOfNewIds = [];

			for (const dirent of dirents) {
				const parsedDirent = {
					...dirent,
					id       : dirent._id,
					parentId : dirent.parentId ?? null,

					...(dirent.isDirectory ? { items : [] } : {}),
				};

				// Add the id to a list to know what elements must be deleted.
				listOfNewIds.push(parsedDirent.id);

				// Add the entity to the TREE than get the reference of the entity.
				if (dirent.isDirectory) {
					state.tree[parsedDirent.id] = parsedDirent;
				} else {
					const items = Array.isArray(state.tree[parsedDirent.parentId]?.items)
						? state.tree[parsedDirent.parentId]?.items
						: [];

					items.push(parsedDirent);

					// Create a map to prevent duplicated entities.
					const itemsMap = new Map(items.map(item => [item.id, item]));

					state.tree[parsedDirent.parentId].items = Array.from(itemsMap.values());
				}

				// Add the entity to the map of the current request (DIRENTS or FILES).
				state.dirents[direntId][parsedDirent.id] = parsedDirent;

				// Add the reference to the references map.
				state.references[referenceKey][parsedDirent.id] = true;
			}

			// Delete the elements that are not in the current request.
			for (const id of Object.keys(state.references[referenceKey])) {
				if (!listOfNewIds.includes(id)) {
					delete state.dirents[direntId][id];
					delete state.references[referenceKey][id];

					if (Array.isArray(state.tree?.[id]?.items)) {
						// eslint-disable-next-line
						state.tree[id].items = state.tree[id].items.filter((item) => item.id != id);
					} else {
						delete state.tree[id];
					}
				}
			}
		},
		clearTreeData : (state) => void(state.tree = {}),
		deleteDirent  : (state, action) => {
			const cloneTree = {...state.tree};
			if (cloneTree[action.payload]) {
				delete cloneTree[action.payload];
				state.tree = cloneTree;
			} else {
				const newFilesList = cloneTree[state.direntId]?.items?.filter(item => item?._id !== action.payload);
				cloneTree[state.direntId].items = newFilesList;
				state.tree = cloneTree;
			}
		},
	},
});

export default DirentsSlice;
