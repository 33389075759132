import { api }       from ".";
import { AuthSlice } from "Store/Slices";

export const authApi = api.injectEndpoints({
	endpoints : builder => ({
		login : builder.mutation({
			query : credentials => ({
				url    : "auth/login",
				method : "POST",
				body   : credentials,
			}),
			extraOptions : { maxRetries : 0 },
		}),

		forgotPassword : builder.mutation({
			queryFn : async ({ email : newEmail }, { getState, dispatch }, extraOptions, baseQuery) => {
				let { email } = getState()._authSlice.user;

				if (newEmail) {
					dispatch(AuthSlice.actions.setEmail(newEmail));
					email = newEmail;
				}

				const result = await baseQuery({
					url    : "/auth/forgot_password",
					method : "POST",
					body   : { email },
				});

				if (result.error) return result;

				const { token } = result.data;

				dispatch(AuthSlice.actions.setToken(token));

				return result;
			},
			extraOptions : { maxRetries : 0 },
		}),
		verifyForgotPassword : builder.mutation({
			queryFn : async ({ code }, { dispatch }, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url    : "auth/verify_password_code",
					method : "POST",
					body   : { code },
				});

				if (result.error) return result;

				const { token } = result.data;

				dispatch(AuthSlice.actions.setToken(token));

				return result;
			},
			extraOptions : { maxRetries : 0 },
		}),
		resetPassword : builder.mutation({
			queryFn : async ({ password }, { dispatch }, extraOptions, baseQuery) => {
				const result = await baseQuery({
					url    : "/auth/reset_password",
					method : "POST",
					body   : { password },
				});

				if (result.error) return result;

				// dispatch(AuthSlice.actions.clearUserData());

				return result;
			},
			extraOptions : { maxRetries : 0 },
		}),
	}),
});
