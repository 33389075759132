import qs from "qs";

// Import Own Components
import { api }          from ".";
import { DirentsSlice } from "Store/Slices";

export const direntsApi = api.injectEndpoints({
	endpoints : builder => ({
		getDirents : builder.query({
			queryFn : async ({ page = 1, direntId }, { dispatch }, extraOptions, baseQuery) => {
				const url = `dirents${(direntId ? `/${direntId}` : "")}?${qs.stringify({ page })}`;

				const result = await baseQuery(url);

				dispatch(DirentsSlice.actions.saveDirentsGridData({ result, direntId }));

				return result;
			},
			providesTags : (result) =>
				result
					? [
						...(result.docs?.map?.(({ _id }) => ({
							id   : _id,
							type : "dirents",
						})) ?? []),
						{ type : "dirents", id : "LIST" },
					]
					: [{ type : "dirents", id : "LIST" }],
		}),
		addDirent : builder.mutation({
			query : ({ direntData, direntId }) => ({
				url    : `dirents${(direntId ? `/${direntId}` : "")}`,
				method : "POST",
				body   : direntData,
			}),
			invalidatesTags : ["dirents", "dirents_files"],
			extraOptions    : { maxRetries : 0 },
		}),
		getCurrentPermissions : builder.mutation({
			query        : direntId => `dirents/${direntId}/permissions/current`,
			extraOptions : { maxRetries : 0 },
		}),
		editDirent : builder.mutation({
			query : ({ direntData, direntId }) => ({
				url    : `dirents/${direntId}`,
				method : "PUT",
				body   : direntData,
			}),
			invalidatesTags : ["dirents", "dirents_files"],
			extraOptions    : { maxRetries : 0 },
		}),
		addToFavorites : builder.mutation({
			query : direntId => ({
				url    : `dirents/${direntId}/favorites`,
				method : "PATCH",
			}),
			invalidatesTags : result => result?.direntId
				? [{ type : "dirents", id : result.direntId }]
				: [],
			extraOptions : { maxRetries : 0 },
		}),
		addDirentFile : builder.mutation({
			query : ({ direntFileData, direntId }) => ({
				url    : `dirents/${direntId}/files`,
				method : "POST",
				body   : direntFileData,
			}),
			invalidatesTags : ["dirents", "dirents_files"],
			extraOptions    : { maxRetries : 0 },
		}),
		deleteDirent : builder.mutation({
			query : direntId => ({
				url    : `dirents/${direntId}`,
				method : "DELETE",
			}),
			invalidatesTags : result => result?.direntId
				? [{ type : "dirents", id : result.direntId }]
				: [],
			extraOptions : { maxRetries : 2 },
		}),
		searchUsersGroups : builder.query({
			query        : ({direntid}) => (`dirents/${direntid}/permissions/users_groups`),
			providesTags : ["dirents"],
		}),
	}),
});
