import { createSlice } from "@reduxjs/toolkit";

// Import Own Components
import { authApi }    from "Store/api/auth";
import { direntsApi } from "Store/api/dirents";

const initialState = {
	token            : "",
	loggedIn         : false,
	user             : {},
	stepRecoverCount : 1,
};

const AuthSlice = createSlice({
	name     : "auth",
	initialState,
	reducers : {
		setToken        : (state, action) => void(state.token = action.payload),
		setEmail        : (state, action) => void(state.user.email = action.payload),
		setStepRecovery : (state, action) => void(state.stepRecoverCount = action.payload),
		clearUserData   : () => initialState,
	},
	extraReducers : builder => {
		builder
			.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
				state.loggedIn = true;
				state.token    = action.payload.token;
				state.user     = action.payload.user;
			})
			.addMatcher(direntsApi.endpoints.addToFavorites.matchFulfilled, (state, action) => {
				if (action.payload.direntId) {
					state.user = state.user ?? {};

					state.user.favoriteDirentIds = Array.isArray(state.user.favoriteDirentIds)
						? state.user.favoriteDirentIds
						: [];

					if (action.payload.isFavorite) {
						state.user.favoriteDirentIds.push(action.payload.direntId);
					} else {
					// eslint-disable-next-line
					state.user.favoriteDirentIds = state.user.favoriteDirentIds.filter(id => id != action.payload.direntId);
					}
				}
			});
	},
});

export default AuthSlice;
